<template>
  <div :class="['clock', !timer && 'clock--ended']">
    <span class="clock__part clock--minutes">
      {{ minutes ? minutes : '00' }}
    </span>
    <span class="clock__colon">:</span>
    <span class="clock__part clock--seconds">
      {{ seconds ? seconds : '00' }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'LoginTimer',
  emits: ['clock-is-done'],
  props: {
    time: {
      type: Number,
      default: 90
    }
  },
  data() {
    return {
      minutes: '01',
      seconds: '30',
      timer: null,
      start: false,
      leftTime: 90
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      if (!this.start) {
        this.start = true
        this.leftTime = parseInt(this.time)
      }
      this.leftTime--
      if (this.leftTime < 0) {
        this.$emit('clock-is-done')
        clearInterval(this.timer)
        return (this.timer = null)
      }
      const minutes = Math.floor(this.leftTime / 60)
      this.minutes = minutes < 10 ? '0' + minutes : minutes

      const seconds = Math.floor(this.leftTime % 60)
      this.seconds = seconds < 10 ? '0' + seconds : seconds
    }, 1000)
  },
  beforeUnmount() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss" scoped>
.clock {
  direction: ltr;
  color: rgba(#fff, 0.7);
  width: 90px;
  padding: 0 10px;
  border-radius: 10px;
  font-size: 20px;
  text-align: center;
  background-color: rgba(#fff, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.clock__part {
  position: relative;
  display: inline-block;
  text-align: center;
  height: 45px;
  line-height: 45px;
  font-size: 22px;
  // width: 30px;
  border-radius: 10px;
  padding-top: 4px;
}
.clock--ended {
  color: rgba(#fff, 0.3);
}
</style>
