<script setup>
import { ref, onMounted, nextTick, defineEmits, defineProps } from 'vue'
import BaseInputLittle from '@common/BaseInputLittle'
import LoginTimer from './LoginTimer'
const emit = defineEmits([
  'confirmClicked',
  'timeFinished',
  'goBack',
  'updateCode'
])
const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  },
  mobile: {
    type: String
  }
})

const loginBtn = ref()
const handleReceiveCode = (code) => {
  emit('updateCode', code)
  loginBtn.value.focus()
}

const phoneCode = ref()
onMounted(() => {
  nextTick(() => {
    phoneCode.value.$el.querySelector('input').focus()
  })
  setTimeout(() => {
    window.scrollBy({ top: 500 })
  }, 500)
})
</script>

<template>
  <form novalidate class="login-form" @submit.prevent="emit('confirmClicked')">
    <div class="login-form__time-row">
      <LoginTimer :time="90" @clockIsDone="emit('timeFinished')" />
      <a class="login-form__back" @click="emit('goBack')">
        <svg viewBox="0 0 512 512">
          <path
            d="M174.6 252.89l205.16 -205.16c7.2,-7.21 7.2,-18.89 0,-26.1 -7.2,-7.2 -18.88,-7.2 -26.09,0l-221.43 221.44c-7.2,7.2 -7.2,18.88 0,26.09l221.43 221.43c7.33,7.08 19.01,6.87 26.09,-0.46 6.91,-7.15 6.91,-18.48 0,-25.63l-205.13 -205.13 0 -0.03c-0.82,-0.83 -1.33,-1.97 -1.33,-3.23 0,-1.26 0.51,-2.4 1.33,-3.22l-0.03 0z"
          />
        </svg>
      </a>
    </div>
    <h1 class="login-form__title">
      کد فعالسازی را وارد نمایید.
      <br />
      <span class="title--gold" style="margin-top: 10px;">{{ mobile }}</span>
    </h1>
    <BaseInputLittle
      style="margin-top: 10px;"
      light-theme
      ref="phoneCode"
      @finishType="handleReceiveCode"
    />
    <div class="credit-buttons">
      <button type="submit" ref="loginBtn" class="button button--login">
        <span class="button__text">تأیید</span>
        <spinner v-if="loading" />
      </button>
    </div>
  </form>
</template>

<style lang="scss" scoped>
.login-form {
  padding: 0;
}
.button--login {
  width: 90%;
  background-color: #c69d4e;
  margin: 0;
  .button__text {
    color: #000;
  }
}
</style>
